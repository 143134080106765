import React, { forwardRef } from 'react'

interface StepProgressBarProps {
  totalSteps: number
  activeStep: number
}

const StepProgressBar = forwardRef<HTMLDivElement, StepProgressBarProps>(
  ({ totalSteps, activeStep }, ref) => {
    const stepWidth = `${(activeStep / totalSteps) * 100}%`

    return (
      <div
        ref={ref}
        className="h-0.5 w-full bg-gray-200"
        data-testid="step-progress-bar"
      >
        <div
          // max-w-full so it doesn't overflow in case the activeStep is higher than totalSteps
          className="bg-firstbase-primary h-full max-w-full"
          style={{ width: stepWidth }}
          data-testid="step-progress"
        />
      </div>
    )
  }
)
StepProgressBar.displayName = 'StepProgressBar'

export { StepProgressBar }
