import React, { lazy, Suspense, SVGProps } from 'react'
import { IconName } from './Icon.types'
import { dynamicIconImports } from './dynamicIconImports'

export interface IconProps
  extends Omit<
    SVGProps<SVGElement>,
    'size' | 'radius' | 'color' | 'children' | 'ref'
  > {
  name: IconName
}

// Move lazy loading outside the component
const lazyIconComponents = {} as Record<
  IconName,
  React.LazyExoticComponent<any>
>

const getIconComponent = (name: IconName) => {
  if (!lazyIconComponents[name]) {
    lazyIconComponents[name] = lazy(dynamicIconImports[name])
  }
  return lazyIconComponents[name]
}

const Icon = React.forwardRef<SVGSVGElement, IconProps>(
  ({ name, ...props }, ref) => {
    const IconComponent = getIconComponent(name)

    return (
      <Suspense fallback={null}>
        <IconComponent ref={ref} {...props} />
      </Suspense>
    )
  }
)
Icon.displayName = 'Icon'

export { Icon }
