import { gql } from '@apollo/client'

export const ALL_INVENTORY_ITEMS_BASE_FIELDS = gql`
  fragment AllInventoryItemsBaseFields on OrganizationInventory {
    id
    sku {
      id
      vendor {
        id
        name
        code
      }
      category {
        id
        namePlural
        nameSingular
        code
      }
      skuImages
      skuInformation {
        productTitle
      }
      genericCategory
      metadata {
        id
        field
        value
      }
    }
    deployStatus
    deployReason
    description
    warehouse {
      name
      address {
        countryCode
      }
    }
    serialNumber
    conditionStatus
    person {
      id
      forename
      surname
      slug
      address {
        countryCode
        administrativeArea
        locality
      }
      status
    }
    returnOrderItem {
      id
      returnOrderId
    }
    renewalDate
    billingStartDate
    billingEndDate
    firstbaseSupplied
  }
`

export const INVENTORY_BY_SKU_ID_BASE_FIELDS = gql`
  fragment InventoryBySkuIdBaseFields on Inventory {
    id
    serialNumber
    conditionStatus
    deployStatus
    deployReason
    firstbaseSupplied
    renewalDate
    billingStartDate
    billingEndDate
    description
    sku {
      id
      vendor {
        id
        name
        code
      }
      skuImages
      skuInformation {
        productTitle
      }
      metadata {
        id
        field
        value
      }
      category {
        id
        namePlural
        nameSingular
        code
      }
      genericCategory
    }
    warehouse {
      id
      name
      address {
        countryCode
      }
    }
    person {
      id
      slug
      forename
      surname
      address {
        countryCode
      }
    }
    office {
      id
      address {
        countryCode
      }
    }
    organization {
      id
      name
    }
    returnOrderItem {
      id
      returnOrderId
    }
  }
`

export const TAG_BASE_FIELDS = gql`
  fragment TagBaseFields on Tag {
    id
    label
  }
`

export const ORGANIZATION_INVENTORY_TAGS = gql`
  ${TAG_BASE_FIELDS}
  fragment OrganizationInventoryTags on OrganizationInventory {
    tags {
      ...TagBaseFields
    }
  }
`

export const INVENTORY_TAGS = gql`
  ${TAG_BASE_FIELDS}
  fragment InventoryTags on Inventory {
    tags {
      ...TagBaseFields
    }
  }
`

export const INVENTORY_PRICING_BASE_FIELDS = gql`
  fragment InventoryPricingBaseFields on InventoryPricing {
    id
    price
    currencyCode
    regionCode
    priceOption
  }
`
export const INVENTORY_DETAILS_FRAGMENT = gql`
  fragment InventoryDetailsFields on Inventory {
    id
    deployStatus
    deployReason
    description
    serialNumber
    conditionStatus
    firstbaseSupplied
    sku {
      id
      category {
        id
        namePlural
        code
      }
      vendor {
        id
        name
        code
      }
      skuInformation {
        productTitle
      }
      skuImages
      metadata {
        id
        field
        value
      }
    }
  }
`
