import { cn } from '@firstbase/utils'
import * as RadioGroupPrimitive from '@radix-ui/react-radio-group'
import { cva, type VariantProps } from 'class-variance-authority'
import * as React from 'react'

interface RadioGroupProps
  extends React.ComponentPropsWithoutRef<typeof RadioGroupPrimitive.Root> {
  className?: string
}

const ButtonGroup = React.forwardRef<
  React.ElementRef<typeof RadioGroupPrimitive.Root>,
  RadioGroupProps
>(({ className, ...props }, ref) => (
  <RadioGroupPrimitive.Root
    className={cn(
      'flex gap-1 rounded-full border border-gray-300 p-1',
      className
    )}
    {...props}
    ref={ref}
  />
))
ButtonGroup.displayName = 'ButtonGroup'

const buttonGroupItemVariants = cva(
  'data-[state=checked]:text-firstbase-primary data-[state=checked]:bg-firstbase-primary-50 h-8 rounded-full border border-transparent bg-transparent text-sm font-semibold text-gray-600 transition-colors hover:border-gray-100 hover:bg-gray-100 hover:text-gray-900 disabled:cursor-not-allowed disabled:border-gray-100 disabled:bg-gray-100 disabled:text-gray-400 data-[state=checked]:border-gray-200',
  {
    variants: {
      size: {
        sm: 'px-2.5',
        md: 'px-7',
        lg: 'px-14',
      },
    },
    defaultVariants: {
      size: 'md',
    },
  }
)

interface ButtonGroupItemProps
  extends React.ComponentPropsWithoutRef<typeof RadioGroupPrimitive.Item>,
    VariantProps<typeof buttonGroupItemVariants> {
  className?: string
}

const ButtonGroupItem = React.forwardRef<
  React.ElementRef<typeof RadioGroupPrimitive.Item>,
  ButtonGroupItemProps
>(({ className, size, ...props }, ref) => (
  <RadioGroupPrimitive.Item
    ref={ref}
    className={cn(buttonGroupItemVariants({ size }), className)}
    {...props}
  />
))
ButtonGroupItem.displayName = 'ButtonGroupItem'

export { ButtonGroup, ButtonGroupItem }
