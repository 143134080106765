import { Icon, IconName } from '@firstbase/components'
import { forwardRef } from 'react'

interface IconIllustrationProps {
  name: IconName
}

const IconIllustration = forwardRef<HTMLDivElement, IconIllustrationProps>(
  ({ name }, ref) => (
    <div
      ref={ref}
      className="bg-firstbase-primary-50 flex size-12 items-center justify-center rounded-lg"
    >
      <Icon name={name} className="text-firstbase-primary h-7 max-w-7" />
    </div>
  )
)
IconIllustration.displayName = 'IconIllustration'

export { IconIllustration }
