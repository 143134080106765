import { gql } from '@apollo/client'

import { SUPPRESS_DEFAULT_ERROR } from '@firstbase/config/apollo'
import { TAG_BASE_FIELDS } from './fragments'

export const BUY_INVENTORY = gql`
  mutation buyInventory($ids: [ID]) {
    buyInventory(ids: $ids) {
      url
    }
  }
`

export const CREATE_LEGACY_INVENTORY = gql`
  mutation createLegacyInventory(
    $createLegacyInventory: CreateLegacyInventory!
  ) {
    createLegacyInventory(createLegacyInventory: $createLegacyInventory) {
      id
    }
  }
`

export const ADD_TAG_TO_INVENTORY = gql`
  ${TAG_BASE_FIELDS}
  mutation addTagToInventory${SUPPRESS_DEFAULT_ERROR}($inventoryId: ID!, $tagLabel: String!) {
    addTagToInventory(inventoryId: $inventoryId, tagLabel: $tagLabel) {
      ...TagBaseFields
      normalizedLabel
    }
  }
`

export const REMOVE_TAG_FROM_INVENTORY = gql`
  ${TAG_BASE_FIELDS}
  mutation removeTagFromInventory($inventoryId: ID!, $tagId: ID!) {
    removeTagFromInventory(inventoryId: $inventoryId, tagId: $tagId) {
      ...TagBaseFields
      normalizedLabel
    }
  }
`

export const DROP_OFF_INVENTORIES_AT_OFFICE = gql`
  mutation dropOffInventoriesAtOffice($officeId: ID!, $ids: [ID]) {
    dropOffInventoriesAtOffice(officeId: $officeId, ids: $ids) {
      id
    }
  }
`
