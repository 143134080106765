import { gql } from '@apollo/client'

import {
  ALL_INVENTORY_ITEMS_BASE_FIELDS,
  INVENTORY_BY_SKU_ID_BASE_FIELDS,
  INVENTORY_DETAILS_FRAGMENT,
  INVENTORY_PRICING_BASE_FIELDS,
  TAG_BASE_FIELDS,
} from './fragments'

export const GET_INVENTORY_BY_SKU_WITH_OFFICE = gql`
  ${INVENTORY_BY_SKU_ID_BASE_FIELDS}
  ${TAG_BASE_FIELDS}
  ${INVENTORY_PRICING_BASE_FIELDS}
  query getInventoriesBySkuIdWithOffice(
    $skuId: ID!
    $filters: InventoryFilter
  ) {
    getInventoriesBySkuId(skuId: $skuId, filter: $filters) {
      ...InventoryBySkuIdBaseFields
      office {
        id
        name
      }
      assetEvents {
        eventType
        timestamp
        userName
        details {
          detailType
          field
          value
          previousValue
        }
      }
      tags {
        ...TagBaseFields
      }
      pricing {
        ...InventoryPricingBaseFields
      }
      warranty {
        id
        warrantyName
        warrantyDetail {
          term
        }
      }
    }
  }
`

export const GET_INVENTORY_FILTER_DATA = gql`
  ${TAG_BASE_FIELDS}
  query getInventoryFilterData {
    getRelevantConditions
    getRelevantDeployStatuses
    getRelevantDeployReasons
    getRelevantInventoryLocations {
      administrativeArea
      countryCode
    }
    getAllActiveVendors {
      id
      name
      code
    }
    getAllActiveCategories {
      id
      code
      namePlural
    }
    getOrganization {
      id
      name
      tags(
        pagingAndSorting: {
          pageNumber: 1
          pageSize: 1000
          sort: [{ field: "usageCount", direction: DESC }]
        }
        filter: { labelStartsWith: "", usageCountGreaterThan: 0 }
      ) {
        data {
          ...TagBaseFields
        }
      }
    }
  }
`

export const QUERY_ALL_ORGANIZATION_INVENTORY_ITEMS_WITH_OFFICE_AND_ASSET_EVENTS = gql`
  ${ALL_INVENTORY_ITEMS_BASE_FIELDS}
  ${TAG_BASE_FIELDS}
  ${INVENTORY_PRICING_BASE_FIELDS}
  query getAllOrganizationInventoryItemsWithOfficeAndAssetEvents(
    $pageNumber: Int! = 1
    $pageSize: Int! = 25
    $direction: SortDirection! = ASC
    $field: String! = "person.forename"
    $filters: InventoryFilter!
  ) {
    getOrganization {
      id
      name
      offices(pagingAndSorting: { pageNumber: 1, pageSize: 1000 }) {
        data {
          id
          name
        }
      }
      inventories(
        pagingAndSorting: {
          pageNumber: $pageNumber
          pageSize: $pageSize
          sort: { field: $field, direction: $direction }
        }
        filter: $filters
      ) {
        totalPages
        data {
          ...AllInventoryItemsBaseFields
          assetEvents {
            eventType
            timestamp
            userName
            details {
              detailType
              field
              value
              previousValue
            }
          }
          office {
            id
            name
            address {
              id
              countryCode
            }
          }
          tags {
            ...TagBaseFields
          }
          pricing {
            ...InventoryPricingBaseFields
          }
          warranty {
            id
            warrantyName
            warrantyDetail {
              term
            }
          }
        }
      }
    }
  }
`

export const GET_INVENTORY_BY_ID_FOR_MODAL = gql`
  ${ALL_INVENTORY_ITEMS_BASE_FIELDS}
  ${TAG_BASE_FIELDS}
  ${INVENTORY_PRICING_BASE_FIELDS}
  query getInventoryByIdForModal($id: ID!) {
    getOrganization {
      id
      inventoryById(id: $id) {
        ...AllInventoryItemsBaseFields
        assetEvents {
          eventType
          timestamp
          userName
          details {
            detailType
            field
            value
            previousValue
          }
        }
        office {
          id
          name
          address {
            id
            countryCode
          }
        }
        tags {
          ...TagBaseFields
        }
        pricing {
          ...InventoryPricingBaseFields
        }
        warranty {
          id
          warrantyName
          warrantyDetail {
            term
          }
        }
      }
    }
  }
`

export const EXPORT_ASSET_HISTORY = gql`
  query exportAssetHistory(
    $pageNumber: Int! = 1
    $pageSize: Int! = 25
    $direction: SortDirection! = ASC
    $field: String! = "person.forename"
    $filters: InventoryFilter!
  ) {
    getOrganization {
      id
      inventories(
        pagingAndSorting: {
          pageNumber: $pageNumber
          pageSize: $pageSize
          sort: { field: $field, direction: $direction }
        }
        filter: $filters
      ) {
        totalPages
        data {
          id
          serialNumber
          assetEvents {
            eventType
            timestamp
            userName
            details {
              detailType
              field
              value
              previousValue
            }
          }
        }
      }
    }
  }
`

export const EXPORT_ASSET_UPGRADE = gql`
  query exportAssetUpgrade(
    $filters: InventoryFilter!
    $pageSize: Int!
    $pageNumber: Int! = 1
    $direction: SortDirection! = ASC
    $field: String! = "person.forename"
  ) {
    getOrganization {
      id
      inventories(
        pagingAndSorting: {
          pageSize: $pageSize
          pageNumber: $pageNumber
          sort: { field: $field, direction: $direction }
        }
        filter: $filters
      ) {
        totalElements
        totalPages
        data {
          id
          deployStatus
          sku {
            skuInformation {
              productTitle
            }
          }
          person {
            slug
            surname
            forename
            address {
              countryCode
              addressLine1
              addressLine2
              administrativeArea
              postalCode
              locality
              phoneNumber
            }
          }
        }
      }
    }
  }
`

export const EXPORT_ALL_ORGANIZATION_INVENTORY_ITEMS_UPDATED = gql`
  ${TAG_BASE_FIELDS}
  ${INVENTORY_PRICING_BASE_FIELDS}
  query exportAllOrganizationInventoryItemsUpdated(
    $pageNumber: Int! = 1
    $pageSize: Int! = 25
    $direction: SortDirection! = ASC
    $field: String! = "person.forename"
    $filters: InventoryFilter!
  ) {
    getOrganization {
      id
      name
      inventories(
        pagingAndSorting: {
          pageNumber: $pageNumber
          pageSize: $pageSize
          sort: { field: $field, direction: $direction }
        }
        filter: $filters
      ) {
        totalPages
        totalElements
        data {
          id
          deployStatus
          deployReason
          firstbaseSupplied
          serialNumber
          conditionStatus
          renewalDate
          billingStartDate
          billingEndDate
          procurementMethod
          description
          pricing {
            ...InventoryPricingBaseFields
          }
          sku {
            vendor {
              id
              name
              code
            }
            category {
              id
              nameSingular
            }
            skuInformation {
              productTitle
            }
          }
          warehouse {
            id
            name
            address {
              countryCode
              administrativeArea
            }
          }
          person {
            id
            forename
            surname
            address {
              countryCode
              administrativeArea
            }
          }
          office {
            id
            name
            address {
              countryCode
              administrativeArea
            }
          }
          tags {
            ...TagBaseFields
          }
          warranty {
            id
            warrantyName
            warrantyDetail {
              term
            }
          }
        }
      }
    }
  }
`

export const GET_INVENTORY_BUY_OUT_PRICES = gql`
  query getInventoryBuyOutPrices {
    getInventoryBuyOutPrices {
      inventoryId
      currencyCode
      price
    }
  }
`

export const VALIDATE_SERIAL_NUMBER = gql`
  query validateSerialNumber($serialNumber: String!) {
    inventoryWithSerialNumberExists(request: { serial: $serialNumber })
  }
`

export const GET_ORGANIZATION_TAGS = gql`
  ${TAG_BASE_FIELDS}
  query getOrganizationTags(
    $pagingAndSorting: PagingAndSorting!
    $tagsFilter: TagsFilter!
  ) {
    getOrganization {
      id
      tags(pagingAndSorting: $pagingAndSorting, filter: $tagsFilter) {
        size
        totalElements
        totalPages
        pageNumber
        data {
          ...TagBaseFields
        }
      }
    }
  }
`

export const GET_ORGANIZATION_INVENTORY_BY_ID = gql`
  query getOrganizationInventoryById($id: ID!) {
    getOrganization {
      id
      inventoryById(id: $id) {
        id
        assetEvents {
          eventType
          timestamp
          userName
          details {
            detailType
            field
            value
            previousValue
          }
        }
      }
    }
  }
`

export const EXPORT_ALL_ORGANIZATION_INVENTORY_ITEMS_WITH_METADATA = gql`
  ${TAG_BASE_FIELDS}
  ${INVENTORY_PRICING_BASE_FIELDS}
  query exportAllOrganizationInventoryItemsWithMetadata(
    $pageNumber: Int! = 1
    $pageSize: Int! = 25
    $direction: SortDirection! = ASC
    $field: String! = "person.forename"
    $filters: InventoryFilter!
  ) {
    getOrganization {
      id
      name
      inventories(
        pagingAndSorting: {
          pageNumber: $pageNumber
          pageSize: $pageSize
          sort: { field: $field, direction: $direction }
        }
        filter: $filters
      ) {
        totalPages
        totalElements
        data {
          id
          deployStatus
          deployReason
          firstbaseSupplied
          serialNumber
          conditionStatus
          renewalDate
          description
          billingStartDate
          billingEndDate
          procurementMethod
          pricing {
            ...InventoryPricingBaseFields
          }
          sku {
            vendor {
              id
              name
              code
            }
            category {
              id
              nameSingular
            }
            skuInformation {
              productTitle
            }
            metadata {
              id
              field
              value
            }
          }
          warehouse {
            id
            name
            address {
              countryCode
              administrativeArea
            }
          }
          person {
            id
            forename
            surname
            address {
              countryCode
              administrativeArea
            }
          }
          office {
            id
            name
            address {
              countryCode
              administrativeArea
            }
          }
          tags {
            ...TagBaseFields
          }
          warranty {
            id
            warrantyName
            warrantyDetail {
              term
            }
          }
        }
      }
    }
  }
`
export const GET_ORGANIZATION_MDM_PROVIDERS = gql`
  query getOrganizationMDMProviders {
    getOrganization {
      id
      mdmProviders {
        id
        name
      }
    }
  }
`

export const GET_ORGANIZATION_INVENTORY_BY_ID_WITH_MDM_DETAILS = gql`
  query getOrganizationInventoryByIdWithMDMDetails($id: ID!) {
    getOrganization {
      id
      inventoryById(id: $id) {
        id
        mdmDetails {
          id
          source
          securityRating
          lastUpdated
          serial
          operatingSystem {
            type
            name
            version
          }
          diskEncrypted
          sessionLock
          firewall
          apps {
            type
            name
            version
          }
        }
      }
    }
  }
`

export const GET_ELIGIBLE_AND_INELIGIBLE_INVENTORIES = gql`
  ${INVENTORY_DETAILS_FRAGMENT}
  query getEligibleAndIneligibleInventories(
    $personId: ID!
    $destination: Destination!
  ) {
    getEligibleAndIneligibleInventories(
      personId: $personId
      destination: $destination
    ) {
      eligible {
        equipmentMustBeReturned {
          ...InventoryDetailsFields
        }
        equipmentCanBeReturned {
          ...InventoryDetailsFields
        }
      }
      ineligible {
        ...InventoryDetailsFields
      }
    }
  }
`
