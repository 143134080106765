/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum AlertType {
  ORDER_APPROVAL = "ORDER_APPROVAL",
}

export enum AppType {
  ANTI_VIRUS = "ANTI_VIRUS",
  BROWSER = "BROWSER",
  FIREWALL = "FIREWALL",
  OS = "OS",
  PASSWORD_MANAGER = "PASSWORD_MANAGER",
}

export enum AsnError {
  UNKNOWN_OWNER_CODE = "UNKNOWN_OWNER_CODE",
  UNSCHEDULED_DELIVERIES = "UNSCHEDULED_DELIVERIES",
}

export enum CatalogBillingType {
  PURCHASE = "PURCHASE",
  SUBSCRIPTION = "SUBSCRIPTION",
}

export enum CatalogSortableFields {
  BRAND = "BRAND",
  CATEGORY = "CATEGORY",
  TITLE = "TITLE",
}

export enum ConjunctiveOperator {
  AND = "AND",
  OR = "OR",
}

export enum ContractCommitmentType {
  ADD_ON = "ADD_ON",
  PREPAID = "PREPAID",
  ROLLOVER = "ROLLOVER",
}

export enum ContractLevelCommitmentType {
  PAY_AS_YOU_GO = "PAY_AS_YOU_GO",
  PRE_COMMITTED = "PRE_COMMITTED",
}

export enum ContractStatus {
  ACTIVE = "ACTIVE",
  NOT_ACTIVE = "NOT_ACTIVE",
}

export enum DeployReason {
  ASSIGNED_TO_ADMIN = "ASSIGNED_TO_ADMIN",
  AWAITING_INFORMATION = "AWAITING_INFORMATION",
  BOUGHT_OUT = "BOUGHT_OUT",
  CANCELLED_BY_FIRSTBASE = "CANCELLED_BY_FIRSTBASE",
  CANCELLED_BY_VENDOR = "CANCELLED_BY_VENDOR",
  CHURNED_CUSTOMER = "CHURNED_CUSTOMER",
  DATA_ERROR = "DATA_ERROR",
  DATA_HOLD = "DATA_HOLD",
  DECOMMISSIONED_BY_EMPLOYER = "DECOMMISSIONED_BY_EMPLOYER",
  DECOMMISSIONED_BY_FIRSTBASE = "DECOMMISSIONED_BY_FIRSTBASE",
  END_OF_USEFUL_LIFE_DESTROYED = "END_OF_USEFUL_LIFE_DESTROYED",
  END_OF_USEFUL_LIFE_DONATED = "END_OF_USEFUL_LIFE_DONATED",
  GIFTED_BY_EMPLOYER = "GIFTED_BY_EMPLOYER",
  GIFTED_BY_FIRSTBASE = "GIFTED_BY_FIRSTBASE",
  LOCKED_APPLE_ACTIVATION = "LOCKED_APPLE_ACTIVATION",
  LOCKED_FMD_REPORTED_LOST_STOLEN = "LOCKED_FMD_REPORTED_LOST_STOLEN",
  LOCKED_MDM = "LOCKED_MDM",
  LOCKED_PIN = "LOCKED_PIN",
  LOST_BY_FIRSTBASE = "LOST_BY_FIRSTBASE",
  LOST_BY_WORKER = "LOST_BY_WORKER",
  LOST_IN_TRANSIT = "LOST_IN_TRANSIT",
  MARKED_FOR_DESTRUCTION = "MARKED_FOR_DESTRUCTION",
  NA = "NA",
  NEVER_RECEIVED_BY_WORKER = "NEVER_RECEIVED_BY_WORKER",
  NEVER_RETURNED_BY_WORKER = "NEVER_RETURNED_BY_WORKER",
  OTHER = "OTHER",
  OUT_FOR_DESTRUCTION = "OUT_FOR_DESTRUCTION",
  OUT_FOR_REPAIR = "OUT_FOR_REPAIR",
  PENDING_BUY_OUT = "PENDING_BUY_OUT",
  PENDING_FULFILLMENT = "PENDING_FULFILLMENT",
  PERSONAL_DEVICE = "PERSONAL_DEVICE",
  REQUIRES_CLEANING = "REQUIRES_CLEANING",
  REQUIRES_REPAIR = "REQUIRES_REPAIR",
  RETURN_IN_PROGRESS = "RETURN_IN_PROGRESS",
  SENT_TO_OFFICE = "SENT_TO_OFFICE",
  UNRESPONSIVE_WORKER = "UNRESPONSIVE_WORKER",
  UNSUPPORTED_REGION = "UNSUPPORTED_REGION",
}

export enum DeployStatus {
  ARCHIVED = "ARCHIVED",
  AVAILABLE = "AVAILABLE",
  AWAITING_ARRIVAL = "AWAITING_ARRIVAL",
  DEPLOYED = "DEPLOYED",
  GIFTED = "GIFTED",
  LOCKED = "LOCKED",
  ON_HOLD = "ON_HOLD",
  OUT_FOR_REPAIR = "OUT_FOR_REPAIR",
  UNAVAILABLE = "UNAVAILABLE",
  VOIDED = "VOIDED",
}

export enum Destination {
  FIRSTBASE = "FIRSTBASE",
  OFFICE = "OFFICE",
}

export enum EmailNotificationType {
  APPROVAL = "APPROVAL",
  COMPLETED_OFFBOARDING = "COMPLETED_OFFBOARDING",
  ORDER_CONFIRMATION = "ORDER_CONFIRMATION",
  ORDER_SHIPPED = "ORDER_SHIPPED",
  RETURN_DETAILS_CONFIRMED = "RETURN_DETAILS_CONFIRMED",
}

export enum ExpeditedShippingOption {
  EXPEDITED_PREMIUM = "EXPEDITED_PREMIUM",
  EXPEDITED_STANDARD = "EXPEDITED_STANDARD",
}

export enum FeatureStatus {
  ACTIVE = "ACTIVE",
}

export enum GroupName {
  APP_ADMIN = "APP_ADMIN",
  APP_ADMIN_READ_ONLY = "APP_ADMIN_READ_ONLY",
  APP_LEAVER = "APP_LEAVER",
  APP_USER = "APP_USER",
  MGMT_ADMIN = "MGMT_ADMIN",
  MGMT_USER = "MGMT_USER",
}

export enum IntegrationProvider {
  JAMF = "JAMF",
  JUMPCLOUD = "JUMPCLOUD",
  KANDJI = "KANDJI",
  MERGE = "MERGE",
  SAILPOINT = "SAILPOINT",
  WEBHOOK = "WEBHOOK",
}

export enum IntegrationSyncType {
  MDM_DEVICE_DISCOVERY = "MDM_DEVICE_DISCOVERY",
  MDM_DEVICE_SYNC = "MDM_DEVICE_SYNC",
  MDM_DEVICE_UPDATE = "MDM_DEVICE_UPDATE",
  OFFBOARD_USER = "OFFBOARD_USER",
  ONBOARD_USER = "ONBOARD_USER",
  SYNC_ASSET = "SYNC_ASSET",
  UPDATE_USER = "UPDATE_USER",
}

export enum InventoryConditionStatus {
  NEW = "NEW",
  UNUSABLE = "UNUSABLE",
  USED = "USED",
  USED_FAIR = "USED_FAIR",
  USED_GOOD = "USED_GOOD",
  USED_LIKE_NEW = "USED_LIKE_NEW",
  USED_POOR = "USED_POOR",
}

export enum InventoryFilterRange {
  IN_OVER_SIX_MONTHS = "IN_OVER_SIX_MONTHS",
  IN_THREE_TO_SIX_MONTHS = "IN_THREE_TO_SIX_MONTHS",
  PAST = "PAST",
  WITHIN_ONE_MONTH = "WITHIN_ONE_MONTH",
  WITHIN_THREE_MONTHS = "WITHIN_THREE_MONTHS",
}

export enum InventoryOrderCondition {
  NEW = "NEW",
  USED = "USED",
}

export enum InventoryOrderStatus {
  CANCELLED = "CANCELLED",
  COMPLETE = "COMPLETE",
  ORDERED = "ORDERED",
  PROCESSING = "PROCESSING",
  SHIPPED = "SHIPPED",
}

export enum MergeTenant {
  EU = "EU",
  US = "US",
}

export enum OffboardPersonReturnType {
  DROPPED_TO_OFFICE = "DROPPED_TO_OFFICE",
  SHIP_TO_FIRSTBASE = "SHIP_TO_FIRSTBASE",
}

/**
 *  the return statuses are handled in Returns.graphqls file
 */
export enum OrderStatus {
  APPROVED = "APPROVED",
  AWAITING_APPROVAL = "AWAITING_APPROVAL",
  AWAITING_DELIVERY_SCHEDULING = "AWAITING_DELIVERY_SCHEDULING",
  AWAITING_FULFILLMENT = "AWAITING_FULFILLMENT",
  CANCELLED = "CANCELLED",
  COMPLETED = "COMPLETED",
  CONFIRMED = "CONFIRMED",
  DECLINED = "DECLINED",
  DELIVERED = "DELIVERED",
  DELIVERY_SCHEDULED = "DELIVERY_SCHEDULED",
  NONE = "NONE",
  NOT_COMPLETED = "NOT_COMPLETED",
  NOT_RETURNED = "NOT_RETURNED",
  ORDERED = "ORDERED",
  ORDERED_NOT_SHIPPED = "ORDERED_NOT_SHIPPED",
  PARTIALLY_COMPLETED = "PARTIALLY_COMPLETED",
  PARTIALLY_SHIPPED = "PARTIALLY_SHIPPED",
  PENDING = "PENDING",
  PROCESSING = "PROCESSING",
  RECEIVED_IN_WAREHOUSE = "RECEIVED_IN_WAREHOUSE",
  RETURNED = "RETURNED",
  RETURN_KIT_DELIVERED = "RETURN_KIT_DELIVERED",
  RETURN_KIT_SENT = "RETURN_KIT_SENT",
  RETURN_KIT_SENT_BACK = "RETURN_KIT_SENT_BACK",
  SHIPPED = "SHIPPED",
  SUBMITTED = "SUBMITTED",
}

export enum OrderType {
  ORDER = "ORDER",
  RETURN = "RETURN",
}

export enum OrganizationStatus {
  DEMO = "DEMO",
  INACTIVE = "INACTIVE",
  LIVE = "LIVE",
}

export enum PersonStatus {
  ACTIVE = "ACTIVE",
  CANNOT_REACTIVATE = "CANNOT_REACTIVATE",
  DELETED = "DELETED",
  INACTIVE = "INACTIVE",
  INVITED = "INVITED",
  OFFBOARDING = "OFFBOARDING",
}

export enum PreferredCondition {
  NEW = "NEW",
  USED = "USED",
}

export enum ProductPriceBillingType {
  PURCHASED = "PURCHASED",
  SUBSCRIPTION = "SUBSCRIPTION",
}

export enum ProductTierStatus {
  ACTIVE = "ACTIVE",
  NOT_ACTIVE = "NOT_ACTIVE",
}

export enum RegionCode {
  AD = "AD",
  AE = "AE",
  AF = "AF",
  AG = "AG",
  AI = "AI",
  AL = "AL",
  AM = "AM",
  AO = "AO",
  AQ = "AQ",
  AR = "AR",
  AS = "AS",
  AT = "AT",
  AU = "AU",
  AW = "AW",
  AX = "AX",
  AZ = "AZ",
  BA = "BA",
  BB = "BB",
  BD = "BD",
  BE = "BE",
  BF = "BF",
  BG = "BG",
  BH = "BH",
  BI = "BI",
  BJ = "BJ",
  BL = "BL",
  BM = "BM",
  BN = "BN",
  BO = "BO",
  BQ = "BQ",
  BR = "BR",
  BS = "BS",
  BT = "BT",
  BV = "BV",
  BW = "BW",
  BY = "BY",
  BZ = "BZ",
  CA = "CA",
  CC = "CC",
  CD = "CD",
  CF = "CF",
  CG = "CG",
  CH = "CH",
  CI = "CI",
  CK = "CK",
  CL = "CL",
  CM = "CM",
  CN = "CN",
  CO = "CO",
  CR = "CR",
  CU = "CU",
  CV = "CV",
  CW = "CW",
  CX = "CX",
  CY = "CY",
  CZ = "CZ",
  DE = "DE",
  DJ = "DJ",
  DK = "DK",
  DM = "DM",
  DO = "DO",
  DZ = "DZ",
  EC = "EC",
  EE = "EE",
  EG = "EG",
  EH = "EH",
  ER = "ER",
  ES = "ES",
  ET = "ET",
  FI = "FI",
  FJ = "FJ",
  FK = "FK",
  FM = "FM",
  FO = "FO",
  FR = "FR",
  GA = "GA",
  GB = "GB",
  GD = "GD",
  GE = "GE",
  GF = "GF",
  GG = "GG",
  GH = "GH",
  GI = "GI",
  GL = "GL",
  GM = "GM",
  GN = "GN",
  GP = "GP",
  GQ = "GQ",
  GR = "GR",
  GS = "GS",
  GT = "GT",
  GU = "GU",
  GW = "GW",
  GY = "GY",
  HK = "HK",
  HM = "HM",
  HN = "HN",
  HR = "HR",
  HT = "HT",
  HU = "HU",
  ID = "ID",
  IE = "IE",
  IL = "IL",
  IM = "IM",
  IN = "IN",
  IO = "IO",
  IQ = "IQ",
  IR = "IR",
  IS = "IS",
  IT = "IT",
  JE = "JE",
  JM = "JM",
  JO = "JO",
  JP = "JP",
  KE = "KE",
  KG = "KG",
  KH = "KH",
  KI = "KI",
  KM = "KM",
  KN = "KN",
  KP = "KP",
  KR = "KR",
  KW = "KW",
  KY = "KY",
  KZ = "KZ",
  LA = "LA",
  LB = "LB",
  LC = "LC",
  LI = "LI",
  LK = "LK",
  LR = "LR",
  LS = "LS",
  LT = "LT",
  LU = "LU",
  LV = "LV",
  LY = "LY",
  MA = "MA",
  MC = "MC",
  MD = "MD",
  ME = "ME",
  MF = "MF",
  MG = "MG",
  MH = "MH",
  MK = "MK",
  ML = "ML",
  MM = "MM",
  MN = "MN",
  MO = "MO",
  MP = "MP",
  MQ = "MQ",
  MR = "MR",
  MS = "MS",
  MT = "MT",
  MU = "MU",
  MV = "MV",
  MW = "MW",
  MX = "MX",
  MY = "MY",
  MZ = "MZ",
  NA = "NA",
  NC = "NC",
  NE = "NE",
  NF = "NF",
  NG = "NG",
  NI = "NI",
  NL = "NL",
  NO = "NO",
  NP = "NP",
  NR = "NR",
  NU = "NU",
  NZ = "NZ",
  OM = "OM",
  PA = "PA",
  PE = "PE",
  PF = "PF",
  PG = "PG",
  PH = "PH",
  PK = "PK",
  PL = "PL",
  PM = "PM",
  PN = "PN",
  PR = "PR",
  PS = "PS",
  PT = "PT",
  PW = "PW",
  PY = "PY",
  QA = "QA",
  RE = "RE",
  RO = "RO",
  RS = "RS",
  RU = "RU",
  RW = "RW",
  SA = "SA",
  SB = "SB",
  SC = "SC",
  SD = "SD",
  SE = "SE",
  SG = "SG",
  SH = "SH",
  SI = "SI",
  SJ = "SJ",
  SK = "SK",
  SL = "SL",
  SM = "SM",
  SN = "SN",
  SO = "SO",
  SR = "SR",
  SS = "SS",
  ST = "ST",
  SV = "SV",
  SX = "SX",
  SY = "SY",
  SZ = "SZ",
  TC = "TC",
  TD = "TD",
  TF = "TF",
  TG = "TG",
  TH = "TH",
  TJ = "TJ",
  TK = "TK",
  TL = "TL",
  TM = "TM",
  TN = "TN",
  TO = "TO",
  TR = "TR",
  TT = "TT",
  TV = "TV",
  TW = "TW",
  TZ = "TZ",
  UA = "UA",
  UG = "UG",
  UM = "UM",
  US = "US",
  UY = "UY",
  UZ = "UZ",
  VA = "VA",
  VC = "VC",
  VE = "VE",
  VG = "VG",
  VI = "VI",
  VN = "VN",
  VU = "VU",
  WF = "WF",
  WS = "WS",
  YE = "YE",
  YT = "YT",
  ZA = "ZA",
  ZM = "ZM",
  ZW = "ZW",
}

export enum ReplacementStatus {
  CANCELLED = "CANCELLED",
  COMPLETED = "COMPLETED",
  PARTIALLY_COMPLETED = "PARTIALLY_COMPLETED",
  PROCESSING = "PROCESSING",
  SUBMITTED = "SUBMITTED",
}

export enum RetrievalMethodStatus {
  ARRIVED_AT_PROCESSING_FACILITY = "ARRIVED_AT_PROCESSING_FACILITY",
  CANCELLED = "CANCELLED",
  DELIVERED = "DELIVERED",
  DELIVERY_EXCEPTION = "DELIVERY_EXCEPTION",
  LABEL_CREATED = "LABEL_CREATED",
  NOT_PROCESSED = "NOT_PROCESSED",
  PARTIALLY_PROCESSED = "PARTIALLY_PROCESSED",
  PROCESSED = "PROCESSED",
  REQUESTED = "REQUESTED",
  SENT_BACK = "SENT_BACK",
  SHIPPED = "SHIPPED",
}

export enum ReturnMethodType {
  COURIER = "COURIER",
  DROP_OFF_AT_COURIER_LOCATION = "DROP_OFF_AT_COURIER_LOCATION",
}

export enum ReturnOrderStatus {
  CANCELLED = "CANCELLED",
  COMPLETED = "COMPLETED",
  CONFIRMED = "CONFIRMED",
  NONE = "NONE",
  NOT_COMPLETED = "NOT_COMPLETED",
  NOT_RETURNED = "NOT_RETURNED",
  PARTIALLY_COMPLETED = "PARTIALLY_COMPLETED",
  PENDING = "PENDING",
  PROCESSING = "PROCESSING",
  RECEIVED_IN_WAREHOUSE = "RECEIVED_IN_WAREHOUSE",
  RETURNED = "RETURNED",
  RETURN_KIT_DELIVERED = "RETURN_KIT_DELIVERED",
  RETURN_KIT_SENT = "RETURN_KIT_SENT",
  RETURN_KIT_SENT_BACK = "RETURN_KIT_SENT_BACK",
  SUBMITTED = "SUBMITTED",
}

export enum ReturnReason {
  DAMAGED = "DAMAGED",
  FULFILLMENT_ERROR = "FULFILLMENT_ERROR",
  LOST_BY_PARTNER = "LOST_BY_PARTNER",
  LOST_BY_WORKER = "LOST_BY_WORKER",
  OFFBOARDING = "OFFBOARDING",
  OFF_CYCLE = "OFF_CYCLE",
  OTHER = "OTHER",
  PRODUCT_CHANGE = "PRODUCT_CHANGE",
  STANDARD_LIFECYCLE = "STANDARD_LIFECYCLE",
  UNKNOWN = "UNKNOWN",
  UPGRADE = "UPGRADE",
}

export enum SKUType {
  AUDIO = "AUDIO",
  COMPUTER = "COMPUTER",
  DEFAULT = "DEFAULT",
  DOCKING_STATION = "DOCKING_STATION",
  FURNITURE = "FURNITURE",
  KEYBOARD = "KEYBOARD",
  MONITOR = "MONITOR",
  PERIPHERALS = "PERIPHERALS",
  WARRANTY = "WARRANTY",
  WEBCAM = "WEBCAM",
}

export enum SecurityRating {
  HIGH_RISK = "HIGH_RISK",
  LOW_RISK = "LOW_RISK",
  MODERATE_RISK = "MODERATE_RISK",
  UNKNOWN = "UNKNOWN",
}

export enum ShipmentNoticeOrderCondition {
  NEW = "NEW",
  USED = "USED",
}

export enum ShipmentNoticeOrderItemStatus {
  CANCELLED = "CANCELLED",
  EXPECTED = "EXPECTED",
  NOT_RECEIVED = "NOT_RECEIVED",
  NO_LONGER_EXPECTED = "NO_LONGER_EXPECTED",
  OVER_RECEIVED = "OVER_RECEIVED",
  PARTIALLY_EXPECTED = "PARTIALLY_EXPECTED",
  PARTIALLY_RECEIVED = "PARTIALLY_RECEIVED",
  RECEIVED = "RECEIVED",
}

export enum ShipmentNoticeOrderStatus {
  CANCELLED = "CANCELLED",
  COMPLETED = "COMPLETED",
  NOT_COMPLETED = "NOT_COMPLETED",
  PARTIALLY_COMPLETED = "PARTIALLY_COMPLETED",
  PROCESSING = "PROCESSING",
  SUBMITTED = "SUBMITTED",
}

export enum ShipmentNoticePackageItemStatus {
  CANCELLED = "CANCELLED",
  EXPECTED = "EXPECTED",
  NOT_RECEIVED = "NOT_RECEIVED",
  OVER_RECEIVED = "OVER_RECEIVED",
  PARTIALLY_RECEIVED = "PARTIALLY_RECEIVED",
  RECEIVED = "RECEIVED",
}

export enum ShipmentNoticePackageStatus {
  ARRIVED_AT_PROCESSING_FACILITY = "ARRIVED_AT_PROCESSING_FACILITY",
  CANCELLED = "CANCELLED",
  DELIVERY_EXCEPTION = "DELIVERY_EXCEPTION",
  FULLY_RECEIVED = "FULLY_RECEIVED",
  IN_TRANSIT = "IN_TRANSIT",
  NOT_RECEIVED = "NOT_RECEIVED",
  OUT_FOR_DELIVERY = "OUT_FOR_DELIVERY",
  OVER_RECEIVED = "OVER_RECEIVED",
  PARTIALLY_RECEIVED = "PARTIALLY_RECEIVED",
  PENDING = "PENDING",
}

export enum SortDirection {
  ASC = "ASC",
  DESC = "DESC",
}

export enum SourceType {
  OFFICE = "OFFICE",
  SUPPLIER = "SUPPLIER",
}

export enum SubscriptionInterval {
  MONTHLY = "MONTHLY",
}

export enum SuppliedBy {
  CUSTOMER = "CUSTOMER",
  FIRSTBASE = "FIRSTBASE",
}

export enum TotalOrderStatus {
  APPROVED = "APPROVED",
  CANCELLED = "CANCELLED",
  DECLINED = "DECLINED",
}

export enum UsedEquipmentPreference {
  NEW_FIRST = "NEW_FIRST",
  NEW_ONLY = "NEW_ONLY",
  USED_FIRST = "USED_FIRST",
}

export enum ValidationReason {
  NOT_VALID_PERSON_STATUS = "NOT_VALID_PERSON_STATUS",
  OFFBOARDING_IN_PROGRESS = "OFFBOARDING_IN_PROGRESS",
}

export interface AutomaticOrderRequest {
  packageSlug: string;
  expeditionRequests: (ExpeditedAutomaticPackageSku | null)[];
}

export interface ConnectMergeAccount {
  organizationMergeIntegrationId: string;
  publicToken: string;
}

export interface CreateAddress {
  addressLine1?: string | null;
  addressLine2?: string | null;
  administrativeArea?: string | null;
  countryCode: string;
  dependentLocality?: string | null;
  locality?: string | null;
  postalCode?: string | null;
  sortingCode?: string | null;
  phoneNumber?: string | null;
}

export interface CreateIntegrationConfiguration {
  integrationSyncId: string;
  configuration: string;
}

export interface CreateInventoryOrder {
  warehouseId: string;
  regionId?: string | null;
  vendorSku: string;
  vendorCode: string;
  quantity: number;
  trackingNumber?: string | null;
  firstbaseSupplied?: boolean | null;
  notes?: string | null;
  condition?: InventoryOrderCondition | null;
  expectedShipDate?: any | null;
}

/**
 *  only one of personrId or officeId
 */
export interface CreateLegacyInventory {
  categoryCode: string;
  vendorCode?: string | null;
  description: string;
  serialNumber?: string | null;
  conditionStatus: InventoryConditionStatus;
  renewalDate: any;
  personSlug?: string | null;
  officeId?: string | null;
}

export interface CreateOffice {
  address: CreateAddress;
  organization: string;
  name: string;
  contactName?: string | null;
}

export interface CreateOrder {
  products: WriteOrderItem[];
  shippingAddress: CreateAddress;
  productPackageSlug?: string | null;
}

export interface CreateOrganizationIntegration {
  provider: IntegrationProvider;
  config: string;
}

export interface CreatePackage {
  name: string;
  approvalRequired?: boolean | null;
  skus?: (SKUIdentifier | null)[] | null;
  automaticOrder?: boolean | null;
  rules?: (CreatePackageRule | null)[] | null;
  note?: string | null;
  itCloset?: boolean | null;
}

export interface CreatePackageNote {
  packageId: string;
  categoryId?: string | null;
  note: string;
}

export interface CreatePackageRule {
  categoryCode: string;
  optional?: boolean | null;
  quantity?: number | null;
}

/**
 *  Forename or surname is required
 */
export interface CreatePerson {
  forename?: string | null;
  surname?: string | null;
  email: string;
  secondaryEmail?: string | null;
  address: CreateAddress;
  startDate?: any | null;
  groups?: (GroupName | null)[] | null;
  packageSlugs?: (string | null)[] | null;
  newHire?: boolean | null;
  automaticOrderRequests?: AutomaticOrderRequest[] | null;
  integrations?: UpdatePersonIntegrations | null;
}

export interface CreateReturn {
  returnItems: ReturnItem[];
  returnKitAddress: CreateAddress;
  officeId?: string | null;
}

export interface CreateShipmentNoticeOrder {
  organizationId: string;
  officeId?: string | null;
  vendorId?: string | null;
  warehouseId: string;
  sourceType: SourceType;
  condition?: ShipmentNoticeOrderCondition | null;
  purchaseOrderNumber?: string | null;
  shipmentNoticePackages: CreateShipmentNoticePackage[];
  regionId?: string | null;
}

export interface CreateShipmentNoticeOrderItem {
  skuId: string;
  quantity: number;
}

export interface CreateShipmentNoticePackage {
  shippingCarrier?: string | null;
  trackingCode?: string | null;
  trackingUrl?: string | null;
  shipmentNoticeOrderItems: CreateShipmentNoticeOrderItem[];
}

export interface CreateWelcomeMessage {
  messageText: string;
  active: boolean;
  orgId: string;
}

export interface DateRange {
  begin: any;
  end: any;
}

export interface DisconnectIntegration {
  id: string;
  provider: IntegrationProvider;
}

export interface ExpeditedAutomaticPackageSku {
  skuId: string;
  option: ExpeditedShippingOption;
}

export interface ExpeditedShippingOrderItemUpdate {
  id: string;
  expeditedShippingOption?: ExpeditedShippingOption | null;
}

export interface ExpeditionAvailabilityRequest {
  skuIds: string[];
  countryCode: string;
  administrativeArea?: string | null;
  packageSlug?: string | null;
}

export interface FeatureFilter {
  name?: string | null;
}

export interface GetActiveIntegration {
  id: string;
  provider: IntegrationProvider;
}

export interface InventoryFilter {
  deployStatuses?: DeployStatus[] | null;
  deployReasons?: (DeployReason | null)[] | null;
  conditions?: InventoryConditionStatus[] | null;
  shippedDate?: DateRange | null;
  renewalDate?: DateRange | null;
  locations?: (LocationFilter | null)[] | null;
  searchString?: string | null;
  categories?: string[] | null;
  range?: InventoryFilterRange | null;
  officeIds?: string[] | null;
  brands?: string[] | null;
  tags?: InventoryTagFilter | null;
  suppliedBy?: SuppliedBy | null;
  metadata?: (SKUMetadataFilter | null)[] | null;
  warehouseIds?: string[] | null;
  personIds?: string[] | null;
  priceOptions?: ProductPriceBillingType[] | null;
  billingStartDate?: DateRange | null;
}

export interface InventoryOrderFilter {
  ids?: (string | null)[] | null;
  statuses?: (InventoryOrderStatus | null)[] | null;
  warehouseIds?: (string | null)[] | null;
  organizationIds?: (string | null)[] | null;
  createdDateRange?: DateRange | null;
  firstbaseSupplied?: boolean | null;
  categories?: string[] | null;
  brands?: string[] | null;
  searchString?: string | null;
  expectedShipDateRange?: DateRange | null;
  metadata?: (SKUMetadataFilter | null)[] | null;
}

export interface InventoryTagFilter {
  conjunctiveOperator: ConjunctiveOperator;
  tags: string[];
}

export interface LocationFilter {
  countryCode: string;
  administrativeArea?: string | null;
}

export interface LookupTableColumnUpdate {
  name?: string | null;
  value?: string | null;
}

export interface LookupTableRowUpdate {
  id?: any | null;
  delete?: boolean | null;
  columns?: LookupTableColumnUpdate[] | null;
}

export interface LookupTableUpdate {
  id: any;
  rows: LookupTableRowUpdate[];
}

export interface NotReturnItem {
  inventoryId: string;
  deployReason: DeployReason;
  deployReasonDetail?: string | null;
}

export interface OffboardPersonInput {
  personId: string;
  personLastDate: any;
  returnableItems?: ReturnItem[] | null;
  notReturnableItems?: NotReturnItem[] | null;
  dropOffItems?: string[] | null;
  returnType: OffboardPersonReturnType;
  legacySkus?: UnknownLegacy[] | null;
  officeId?: string | null;
}

export interface PackageFilter {
  name?: string | null;
  active?: boolean | null;
  itCloset?: boolean | null;
}

export interface PagingAndSorting {
  pageNumber: number;
  pageSize: number;
  sort?: (Sort | null)[] | null;
}

export interface PricingFilter {
  regionCodes?: string[] | null;
  billingType?: ProductPriceBillingType | null;
}

export interface PricingOptionInput {
  regionCode: string;
  priceOption: ProductPriceBillingType;
  warrantyId?: string | null;
  removeWarranty?: boolean | null;
}

export interface ProductInformationFilter {
  productNameFilter?: string | null;
  vendorSkuFilter?: string | null;
  skuIdentifierFilter?: string | null;
  firstbaseSuppliedFilter?: boolean | null;
  regionCode?: string | null;
  billingType?: CatalogBillingType | null;
  sortField?: CatalogSortableFields | null;
  sortDirection?: SortDirection | null;
}

export interface ProductPricingFilter {
  searchString?: string | null;
  regions?: string[] | null;
  supplyOption?: ProductPriceBillingType | null;
  brands?: string[] | null;
  categories?: string[] | null;
  firstbaseSupplied?: boolean | null;
  currencyCodes?: string[] | null;
  metadata?: (SKUMetadataFilter | null)[] | null;
}

export interface ProductTierFilter {
  name?: string | null;
  productTierIds?: string[] | null;
}

export interface RetrieveMergeLinkToken {
  mergeSupportedIntegrationId: string;
}

export interface ReturnItem {
  inventoryId: string;
  returnReason: ReturnReason;
  returnReasonComment?: string | null;
  itad?: boolean | null;
}

export interface SKUAssignmentFilter {
  firstbaseSupplied?: boolean | null;
  categories?: string[] | null;
  countryCode?: string | null;
  metadata?: (SKUMetadataFilter | null)[] | null;
  brands?: (string | null)[] | null;
}

export interface SKUFilter {
  searchString?: string | null;
  categories?: string[] | null;
  excludeCategories?: string[] | null;
  brands?: string[] | null;
  deployStatuses?: DeployStatus[] | null;
  deployReasons?: (DeployReason | null)[] | null;
  conditions?: InventoryConditionStatus[] | null;
  shippedDate?: DateRange | null;
  renewalDate?: DateRange | null;
  locations?: (LocationFilter | null)[] | null;
  excludeGenerics?: boolean | null;
  officeIds?: string[] | null;
  countryCode?: string | null;
  tags?: InventoryTagFilter | null;
  metadata?: (SKUMetadataFilter | null)[] | null;
  suppliedBy?: SuppliedBy | null;
  warehouseIds?: string[] | null;
  personIds?: string[] | null;
  priceOptions?: ProductPriceBillingType[] | null;
  billingStartDate?: DateRange | null;
}

export interface SKUIdentifier {
  vendorCode: string;
  vendorSku: string;
}

export interface SKUMetadataFilter {
  field?: string | null;
  value?: string | null;
}

export interface ShipmentNoticeOrderFilter {
  shipmentNoticeOrderIds?: string[] | null;
  organizationIds?: string[] | null;
  warehouseIds?: string[] | null;
  shipmentNoticeOrderStatuses?: ShipmentNoticeOrderStatus[] | null;
  shipmentNoticeOrderConditions?: ShipmentNoticeOrderCondition[] | null;
  sourceTypes?: SourceType[] | null;
  categoryIds?: string[] | null;
  vendorIds?: string[] | null;
  skuIds?: string[] | null;
  trackingCodes?: string[] | null;
  vendorSkus?: string[] | null;
  purchaseOrderNumbers?: string[] | null;
  orderDate?: DateRange | null;
  updatedDate?: DateRange | null;
  shipmentNoticeOrderItemStatuses?: ShipmentNoticeOrderItemStatus[] | null;
  shipmentNoticePackageStatuses?: ShipmentNoticePackageStatus[] | null;
  metadata?: (SKUMetadataFilter | null)[] | null;
}

export interface Sort {
  direction: SortDirection;
  field: string;
}

export interface StandardCatalogFilter {
  pricingFilter?: PricingFilter | null;
  brands?: string[] | null;
  categories?: string[] | null;
  metadata?: (SKUMetadataFilter | null)[] | null;
}

export interface SyncIntegration {
  id: string;
  provider: IntegrationProvider;
}

export interface TagsFilter {
  labelStartsWith?: string | null;
  isHidden?: boolean | null;
  usageCountGreaterThan?: number | null;
}

export interface UnknownLegacy {
  skuId?: string | null;
  quantity?: number | null;
  description?: string | null;
  serialNumber?: string | null;
}

export interface UpdateAddress {
  addressLine1?: string | null;
  addressLine2?: string | null;
  administrativeArea?: string | null;
  countryCode?: string | null;
  dependentLocality?: string | null;
  locality?: string | null;
  postalCode?: string | null;
  sortingCode?: string | null;
  phoneNumber?: string | null;
}

export interface UpdateIntegrationConfiguration {
  id: string;
  configuration: string;
}

export interface UpdateIntegrationSync {
  id: string;
  enabled?: boolean | null;
}

export interface UpdateInventory {
  serialNumber?: string | null;
  warehouseId?: string | null;
  personSlug?: string | null;
  organizationSlug?: string | null;
  active?: boolean | null;
  conditionStatus?: InventoryConditionStatus | null;
  vendorSku?: string | null;
  vendorCode?: string | null;
  deployStatus?: DeployStatus | null;
  deployReason?: DeployReason | null;
  deployReasonDetail?: string | null;
  disassociateOrderItem?: boolean | null;
  mpnValidated?: boolean | null;
  orderItemId?: string | null;
  returnOrderItemId?: string | null;
  clearDeployReason?: boolean | null;
  firstbaseSupplied?: boolean | null;
  renewalDate?: any | null;
  renewalDateOverridden?: boolean | null;
  officeId?: string | null;
  description?: string | null;
  pricingId?: string | null;
  regionId?: string | null;
}

export interface UpdateOffice {
  address?: UpdateAddress | null;
  name?: string | null;
  contactName?: string | null;
}

/**
 *  The update order input type. Used for updating an entire order.
 */
export interface UpdateOrderInOwnOrganization {
  id: string;
  status?: TotalOrderStatus | null;
  products?: (ExpeditedShippingOrderItemUpdate | null)[] | null;
}

export interface UpdateOwnOffboardingInfo {
  equipmentComment?: string | null;
  deviceReturnMethod?: WriteDeviceReturnMethod | null;
  furnitureReturnMethod?: WriteFurnitureReturnMethod | null;
  returnConfirmed?: boolean | null;
}

/**
 *  The update order input type. Used for updating an entire order belonging to the current user.
 */
export interface UpdateOwnOrder {
  id: string;
  status?: TotalOrderStatus | null;
  products?: WriteOrderItem[] | null;
  shippingAddress?: UpdateAddress | null;
}

export interface UpdateOwnOrganization {
  name?: string | null;
  whiteLabel?: UpdateWhiteLabel | null;
  abmNumber?: string | null;
  expediteShipmentsForNewStarters?: boolean | null;
  emailSwappingForNewEmployees?: boolean | null;
  emailSwappingForOldEmployees?: boolean | null;
  itClosetEnabled?: boolean | null;
  code?: string | null;
  integrations?: UpdateOwnOrganizationIntegrations | null;
  emailDomains?: string[] | null;
}

export interface UpdateOwnOrganizationIntegrations {
  notificationEmail?: string | null;
}

export interface UpdateOwnPerson {
  forename?: string | null;
  surname?: string | null;
  email?: string | null;
  secondaryEmail?: string | null;
  startDate?: any | null;
  address?: UpdateAddress | null;
  emailNotifications?: (EmailNotificationType | null)[] | null;
  groups?: (GroupName | null)[] | null;
  offboardingInfo?: UpdateOwnOffboardingInfo | null;
}

export interface UpdatePackage {
  name?: string | null;
  active?: boolean | null;
  approvalRequired?: boolean | null;
  automaticOrder?: boolean | null;
  skus?: (SKUIdentifier | null)[] | null;
  rules?: (UpdatePackageRule | null)[] | null;
  usedEquipmentPreferences?: (UpdateUsedEquipmentPref | null)[] | null;
}

export interface UpdatePackageNote {
  note: string;
  active?: boolean | null;
}

export interface UpdatePackageRule {
  categoryCode?: string | null;
  optional?: boolean | null;
  quantity?: number | null;
}

export interface UpdatePerson {
  forename?: string | null;
  surname?: string | null;
  email?: string | null;
  secondaryEmail?: string | null;
  startDate?: any | null;
  offboardingInfo?: WriteOffboardingInfo | null;
  address?: UpdateAddress | null;
  groups?: (GroupName | null)[] | null;
  status?: PersonStatus | null;
  packageSlugs?: (string | null)[] | null;
  integrations?: UpdatePersonIntegrations | null;
}

/**
 *  Feature flag: se-2560-create-api-endpoint-for-generating-workato-api-jwt
 */
export interface UpdatePersonIntegrations {
  workatoUserId?: number | null;
}

export interface UpdateShipmentNoticeOrder {
  warehouseId?: string | null;
  regionId?: string | null;
  condition?: ShipmentNoticeOrderCondition | null;
  purchaseOrderNumber?: string | null;
  shipmentNoticePackages?: UpdateShipmentNoticePackage[] | null;
}

export interface UpdateShipmentNoticePackage {
  id?: string | null;
  status?: ShipmentNoticePackageStatus | null;
  shippingCarrier?: string | null;
  trackingCode?: string | null;
  trackingUrl?: string | null;
  asnError?: AsnError | null;
  shipmentNoticePackageItems?: UpdateShipmentNoticePackageItem[] | null;
}

export interface UpdateShipmentNoticePackageItem {
  id?: string | null;
  expectedQuantity?: number | null;
  receivedQuantity?: number | null;
  serialNumbers?: string[] | null;
  skuId?: string | null;
}

export interface UpdateTag {
  hidden?: boolean | null;
}

export interface UpdateUsedEquipmentPref {
  categoryCode: string;
  preference: UsedEquipmentPreference;
}

export interface UpdateWelcomeMessage {
  messageText?: string | null;
  active?: boolean | null;
}

export interface UpdateWhiteLabel {
  primary?: string | null;
  accent?: string | null;
}

export interface WriteDeviceReturnMethod {
  type: ReturnMethodType;
  preferredHours?: string | null;
  preferredDays?: (string | null)[] | null;
  unavailableDatesComment?: string | null;
  buildingAccessComment?: string | null;
}

export interface WriteFurnitureReturnMethod {
  disassemblingRequired?: boolean | null;
  stairsAccess?: boolean | null;
  preferredDays?: (string | null)[] | null;
  unavailableDatesComment?: string | null;
  buildingAccessComment?: string | null;
}

export interface WriteOffboardingInfo {
  lastDate?: any | null;
  unknownLegacies?: (UnknownLegacy | null)[] | null;
}

export interface WriteOrderItem {
  id?: string | null;
  vendorSku: string;
  vendorCode: string;
  expeditedShippingOption?: ExpeditedShippingOption | null;
  preferredCondition?: PreferredCondition | null;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
